import React from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { ButtonAdapter } from '../ButtonAdapter';
import { SlotServices } from '../../../../../utils/state/initialStateFactory';
import { FormStatus } from '../../../../../types/form-state';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';

export interface BuyPlanButtonProps {
  slotServices: SlotServices;
  status: FormStatus;
}

export const BuyPlanButton: React.FC<BuyPlanButtonProps> = ({ status }) => {
  const { experiments } = useExperiments();
  const settings = useSettings();
  const label = getContent({
    settings,
    settingsParam: settingsParams.chooseAPlanText,
    experiments,
  });

  return <ButtonAdapter label={label} status={status} />;
};
