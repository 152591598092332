import React, { useEffect } from 'react';
import {
  PaymentOption,
  BorderStyle,
  PaymentType,
  ReservedPaymentOptionLabels,
  ReservedPaymentOptionIds,
} from '../../../../../../types/types';
import {
  Dropdown,
  DropdownTheme,
  DropdownOptionProps,
} from 'wix-ui-tpa/cssVars';
import { SlotService } from '../../../../../../utils/state/initialStateFactory';
import { classes, st } from './ServicePaymentDropdown.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../../settingsParams';
import { ServicePaymentDropdownHooks } from './dataHooks';
import { getServiceSlotIdentifier } from '../../../../../../utils';
import { useFormActions } from '../../../../Hooks/useFormActions';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getContent } from '../../../../../../utils/content/content';
import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';

export type ServicePaymentDropdownOptionProps = {
  service: SlotService;
  paymentOptions: PaymentOption[];
  label: string;
};

export const ServicePaymentDropdown: React.FC<
  ServicePaymentDropdownOptionProps
> = ({ service, paymentOptions, label }) => {
  const { t } = useTranslation();
  const { setServiceSelectedPaymentOption } = useFormActions();
  const [selectedId, setSelectedId] = React.useState<string>();
  const settings = useSettings();
  const theme =
    settings.get(settingsParams.fieldsBorderStyle) === BorderStyle.UNDERLINE
      ? DropdownTheme.Line
      : DropdownTheme.Box;

  const buildPaymentOptionId = (paymentOption: PaymentOption) => {
    return `${paymentOption.id}:${paymentOption.label}`;
  };
  const getPaymentOptionLabel = (paymentOption: PaymentOption) => {
    let optionLabel;
    switch (paymentOption.type) {
      case PaymentType.SINGLE_SESSION: {
        if (paymentOption.label === ReservedPaymentOptionLabels.ONLINE) {
          optionLabel = getContent({
            settings,
            settingsParam: settingsParams.payOnlineText,
          });
        } else if (
          paymentOption.label === ReservedPaymentOptionLabels.OFFLINE
        ) {
          optionLabel = getContent({
            settings,
            settingsParam: settingsParams.payOfflineText,
          });
        }
        break;
      }
      case PaymentType.BUY_PLAN: {
        optionLabel = t(
          'app.bookings-form.payment-section.service-dropdown.buy-a-plan',
          {
            serviceName: paymentOption.label,
          },
        );
        break;
      }
      case PaymentType.USE_PLAN: {
        optionLabel = t(
          'app.bookings-form.payment-section.service-dropdown.use-my-plan',
          {
            serviceName: paymentOption.label,
          },
        );
        break;
      }
      default:
        optionLabel = paymentOption.label;
    }

    return optionLabel.toString();
  };

  const options: DropdownOptionProps[] = (paymentOptions || []).map(
    (paymentOption) => {
      return {
        id: buildPaymentOptionId(paymentOption),
        value: getPaymentOptionLabel(paymentOption),
        subtitle: paymentOption.pricingDetails,
      };
    },
  );

  const findSelectedPaymentOption = (id: string) => {
    return (paymentOptions || []).find((paymentOption) => {
      const [paymentOptionId, optionLabel] = id.split(':');
      return (
        paymentOption.id === paymentOptionId &&
        paymentOption.label === optionLabel
      );
    })!;
  };

  useEffect(() => {
    const selectedPaymentOptionId = service.selectedPaymentOption.id;
    const selectedPaymentType = service.selectedPaymentType;

    if (selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan) {
      const firstBuyPlanOption = paymentOptions.find(
        (option) => option.type === PaymentType.BUY_PLAN,
      );
      if (firstBuyPlanOption) {
        setSelectedId(buildPaymentOptionId(firstBuyPlanOption));
      }
    } else if (
      selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession
    ) {
      if (selectedPaymentType === SelectedPaymentOption.ONLINE) {
        const onlineOption = paymentOptions.find(
          (option) =>
            option.type === PaymentType.SINGLE_SESSION &&
            option.label === ReservedPaymentOptionLabels.ONLINE,
        );
        if (onlineOption) {
          setSelectedId(buildPaymentOptionId(onlineOption));
        }
      } else if (selectedPaymentType === SelectedPaymentOption.OFFLINE) {
        const offlineOption = paymentOptions.find(
          (option) =>
            option.type === PaymentType.SINGLE_SESSION &&
            option.label === ReservedPaymentOptionLabels.OFFLINE,
        );
        if (offlineOption) {
          setSelectedId(buildPaymentOptionId(offlineOption));
        }
      }
    } else {
      const usePlanOption = paymentOptions.find(
        (option) => option.id === selectedPaymentOptionId,
      );
      if (usePlanOption) {
        setSelectedId(buildPaymentOptionId(usePlanOption));
      }
    }
  }, [service.selectedPaymentOption.id]);

  const onChange = async (option: DropdownOptionProps) => {
    setSelectedId(option.id);
    setServiceSelectedPaymentOption(
      service,
      findSelectedPaymentOption(option.id!),
    );
  };

  return (
    <div className={classes.root}>
      <Dropdown
        key={getServiceSlotIdentifier(service.nestedSlot)}
        data-hook={`${
          ServicePaymentDropdownHooks.dropdown
        }_${getServiceSlotIdentifier(service.nestedSlot)}`}
        className={st(classes.dropdown, { theme })}
        options={options}
        disabled={options.length === 1}
        initialSelectedId={selectedId}
        onChange={onChange}
        label={label}
      />
    </div>
  );
};
