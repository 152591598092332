import React from 'react';
import { ReservedPaymentOptionIds } from '../../../../types/types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import {
  ServiceData,
  SlotService,
} from '../../../../utils/state/initialStateFactory';
import { CustomOption } from '../../../../types/dynamicPrice';
import { getMaxNumberOfParticipants } from '../../../../utils/numberOfParticipant';
import { FormStatus } from '../../../../types/form-state';
import { useFormActions } from '../../Hooks/useFormActions';
import PriceOptionDropdown from './PriceOptionDropdown/PriceOptionDropdown';
import { DynamicPriceErrorType, FormError } from '../../../../types/errors';
import { getErrorByType } from '../../../../utils/errors/errors';

export interface PriceOptionDropdownContainerProps {
  serviceData: ServiceData;
  status: FormStatus;
  errors: FormError[];
}

export interface PriceOptionNumberOfParticipants {
  choiceId: string;
  title: string;
  numberOfParticipants: number;
}

const PriceOptionDropdownContainer: React.FC<
  PriceOptionDropdownContainerProps
> = ({ serviceData, status, errors }) => {
  const { onSelectedVariants } = useFormActions();
  const actions = useFormActions();

  if (mapToArray<SlotService>(serviceData.slotServices).length > 1) {
    return null;
  }

  const servicesWithDynamicPriceWithCustomOptions = mapToArray<SlotService>(
    serviceData.slotServices,
  ).filter((slotService) => slotService.dynamicPriceInfo?.customOptions);
  const { clearErrorByTypes } = actions;
  const firstDynamicPriceError = getErrorByType({
    errorType: DynamicPriceErrorType,
    errors,
  });

  return (
    <>
      {servicesWithDynamicPriceWithCustomOptions.map((slotService) =>
        slotService.dynamicPriceInfo?.customOptions?.map(
          (customOption: CustomOption) => {
            return (
              <PriceOptionDropdown
                key={getServiceSlotIdentifier(slotService.nestedSlot)}
                componentKey={getServiceSlotIdentifier(slotService.nestedSlot)}
                showPrice={
                  slotService.selectedPaymentOption.id ===
                  ReservedPaymentOptionIds.SingleSession
                }
                options={customOption.options}
                onDropdownClose={(
                  priceOptionData: PriceOptionNumberOfParticipants[],
                ) => {
                  onSelectedVariants(
                    priceOptionData,
                    customOption.optionId,
                    getServiceSlotIdentifier(slotService.nestedSlot),
                  );
                  clearErrorByTypes([DynamicPriceErrorType]);
                }}
                maxParticipants={getMaxNumberOfParticipants(
                  serviceData,
                  getServiceSlotIdentifier(slotService.nestedSlot),
                )}
                disabled={status === FormStatus.PROCESSING_PAYMENT_DETAILS}
                label={customOption.name}
                error={!!firstDynamicPriceError}
                errorMessage={firstDynamicPriceError?.params?.message}
              />
            );
          },
        ),
      )}
    </>
  );
};

export default PriceOptionDropdownContainer;
